<template lang="pug">
    #notice-board(v-show='!isClose')
        v-alert(border="left" color="#14c6de9c" elevation="2")
            v-row(no-gutters)
                v-col(cols="11")
                    div(:class = "isExpand ? 'text-h6':'text-h6 title_defalut'") {{notice_title}}
                    div(:class = "isExpand ? 'content_expand':'content_defalut'") {{notice_content}}
                v-col(cols="1")
                    v-btn.close_btn(icon color="red" @click="onClose()")
                        v-icon.left mdi-window-close    
                        v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
                            message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
        v-btn.open_btn(block x-small @click="isExpand=!isExpand")
            v-icon(v-if="!isExpand") fa-solid fa-angle-down
            v-icon(v-else) fa-solid fa-angle-up
</template>

<script>
    import NotificationDataService from "@/services/NotificationDataService";
    import messageDialog from "@/components/Dialog/messageDialog";
    import i18n from '/common/plugins/vue-i18n.js';
    import passData from "@/services/passData.js";

    export default {
        components: {
            messageDialog
        },
        data() {
            return {
                //- dialog
                messageDialog: false,
                message: '', 
                //- data
                noticeList:[],
                showIndex:0,
                notice_title:'',
                notice_content:'',
                isClose: true,
                isExpand: false,
                NotificationTimer: null,
                interval:null
            }
        },
        async created() {
            await this.getNotificationTimer()
            NotificationDataService.activatednoticelist()
            .then((response)=>{
                if(response.data.length > 0){
                    this.isClose = false;
                    this.noticeList = response.data;
                    this.notice_title = response.data[this.showIndex].title
                    this.notice_content = response.data[this.showIndex].content
                    if(response.data.length > 1){
                        this.runShowNotice()
                    }
                }
                else{
                    this.isClose = true
                }
            });
        },
        mounted() {
            passData.$on("updateTimer", (e) => {
                this.NotificationTimer = e
            })
            passData.$on("isUpdate", (e) => {
                if(e == true) {
                    this.showIndex = 0
                    NotificationDataService.activatednoticelist()
                    .then((response)=>{
                        if(response.data.length > 0){
                            this.isClose = false;
                            this.noticeList = response.data;
                            this.notice_title = response.data[this.showIndex].title
                            this.notice_content = response.data[this.showIndex].content
                            if(response.data.length > 1){
                                this.runShowNotice()
                            }
                        }
                        else{
                            this.isClose = true
                        }
                    });
                }
            })
        },
        methods: {
            onClose(){
                this.showMessageDialog(i18n.t('NOTIFICATIONSETTING.MESSAGE2'));
            },
            showMessageDialog(message) {
                this.messageDialog = true;
                this.message = message;
            },
            onEmitMessageDialog(val) {
                this.messageDialog = false;
                if(val===true){
                    window.localStorage.setItem('NoticeBoard_IsClose',true)
                    this.isClose = true
                }
            },
            runShowNotice() {
                if(this.interval != null) {
                    clearInterval(this.interval)
                    this.interval = null
                }
                this.interval = 
                    window.setInterval(()=>{
                        if(this.showIndex == this.noticeList.length - 1){
                            this.showIndex = 0
                        }else{
                            this.showIndex += 1
                        }
                        this.notice_title = this.noticeList[this.showIndex].title
                        this.notice_content = this.noticeList[this.showIndex].content
                    },this.NotificationTimer
                )
            },
            async getNotificationTimer() {
                await NotificationDataService.getTimer()
                    .then((response)=>{
                        if(response.data != ""){
                            this.NotificationTimer = response.data;
                        }
                        else{
                            let requestData = {
                                "name":"NOTIFICATION_TIMER",
                                "code":"NOTIFICATION_TIMER",
                                "value":3000
                            }
                            NotificationDataService.createTimer(requestData);
                            this.NotificationTimer = 3000;
                        }
                    });
            },         
        },
    }
</script>