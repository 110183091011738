import Vue from "vue";
import App from "./App.vue";
import i18n from '../common/plugins/vue-i18n.js'
import router from "./router";
import store from './store';
import vuetify from "./plugins/vuetify";
import Chart from "chart.js";
import _ from "lodash";
import '@fortawesome/fontawesome-free/css/all.css';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import firebaseMessaging from './services/api/firebase';
import VueColumnsResizableVuetify from 'vue-columns-resizable-vuetify';
import ColumnsResizableDirective from './directive/ColumnsResizableDirective';
import PersonalSettingService from "@/services/PersonalSettingService";
import PreferenceService from "@/services/PreferenceService";

Vue.prototype._ = _;
Vue.prototype.$messaging = firebaseMessaging;
Vue.config.productionTip = false;
Vue.mixin({
  methods: {
    upgpt_image_func_setting: async function(){
        let upgpt_setting;
        await PreferenceService.getUPGPTSetting().then((response)=>{
            upgpt_setting = response.data;
            upgpt_setting['can_image_search'] = upgpt_setting.image_search_store>0 ?  true : false;
            upgpt_setting['can_image_analysis'] = upgpt_setting.image_analysis>0 ?  true : false;
            upgpt_setting['can_image_process'] = (upgpt_setting.image_search_store+upgpt_setting.image_analysis>0) ?  true : false;
            upgpt_setting['can_cloud_drive'] = upgpt_setting.cloud_drive>0 ?  true : false;
        })
        return upgpt_setting
    },
    getPageDefaultFilter: function(filter_id,filter_list,system_default_filter){
        let default_filter = null
        let default_filter_list = []
        if(filter_id){
            if(filter_list.length>0){
                default_filter_list = filter_list.filter(el => el.id == filter_id)
            }
            if(default_filter_list.length>0){
                default_filter = default_filter_list[0]
            }
            else{
                default_filter = { id: '',name: i18n.t('FILTERSETTING.NO_FILTER') }
            }
        }
        else{
            if(system_default_filter){
                default_filter = system_default_filter
            }else{
                default_filter = { id: '',name: i18n.t('FILTERSETTING.NO_FILTER') }
            }
        }
        return default_filter
    },
    getDefaultFilter: function(page,stage_type_id){
        let user = JSON.parse(window.localStorage.getItem('user'));
        let df = null;
        if( page == "Case" || page == "Opportunity" ){
            try {
                df = user.default_value.default_filter[page][stage_type_id] ? user.default_value.default_filter[page][stage_type_id] : null;
            } 
            catch (error) {
                df = null;
            }
        }
        else{
            try {
                df= user.default_value.default_filter[page]? user.default_value.default_filter[page] : null;
            } 
            catch (error) {
                df = null;
            }
        }
        return df
    },
    getCustomfieldOptItemNameFromList: function (itemList, key) {
        let optName = '';
        let l = null;
        try{
            key = key.map(k=> itemList.find(x=>x.key==k) != undefined ? k:undefined).filter(el=>el)
            optName = key.map(key => itemList.find(x => x.key == key).name).join(',');
        }
        catch{
            l = itemList.find(x => x.key == key);
            optName = l ? l.name : '';
        }
        return optName;
    },
    getItemNameFromList: function (itemList, key) {
      var l = itemList.find(x => x.key == key);
      return l ? l.name : '';
    },
    getItemFromListById: function (itemList, key) {
      var l = itemList.find(x => x.id == key);
      return l ? l : '';
    },
    getItemNameFromListType: function (itemList, key) {
      var l = itemList.find(x => x.key == key);
      return l ? l.type : '';
    },
    getItemNameFromListById: function (itemList, key) {
        var l = itemList.find(x => x.id == key);
        return l ? l.name : '';
    },
    truncateText(value, lenLg, lenMd, lenSm){
      if(value != null){
        if(window.innerWidth >= 1900 && value.length > lenLg){
            return (value.substring(0,lenLg) + ' ...');
        }
        else if(window.innerWidth >= 1600 && window.innerWidth < 1900 && value.length > lenMd){
            return (value.substring(0,lenMd) + ' ...');
        }
        else if(window.innerWidth < 1600 && value.length > lenSm){
            return (value.substring(0,lenSm) + ' ...');
        }
        else{
            return value;
        }
      }
    },
    getFormatAmount(val){                
        if (val !== 0) {
            const fix = 2
            val = Number(val) 
            val = '' + val.toFixed(2) 
            let int = val.slice(0, fix * -1 - 1) 
            let ext = val.slice(fix * -1 - 1) 
            int = int.split('').reverse().join('') 
            let temp = '' 
            for (let i = 0; i < int.length; i++) {
            temp += int[i]
            if ((i + 1) % 3 === 0 && i !== int.length - 1) {
                temp += ',' 
            }
            }
            temp = temp.split('').reverse().join('') 
            temp = temp + ext                                       
            return temp 
        } else {
         return val
        }   
    },
    getLocation(){
        const location = window.localStorage.getItem('location'); 
        return location
    },
    removeMenu(readOnly){
        let menu = document.getElementsByClassName('v-menu__content');
        if(readOnly){
            if(menu.length > 0){
                menu.forEach(e => e.classList.add('hide-menu'));
            }
        }
        else{
            if(menu.length > 0){
                menu.forEach(e => e.classList.remove('hide-menu'));
            }
        }
    },
    getPriorityNumber: function (key) {
        switch(key){
            case "VERY_LOW":
                return  "1";
            case "LOW":
                return  "2";
            case "MEDIUM":
                return  "3";
            case "HIGH":
                return  "4";
            case "VERY_HIGH":
                return  "5";
            default:
                break;
        }
    },
    getPriority: function (key) {
        switch(key){
            case "1":
                return  "VERY_LOW";
            case "2":
                return  "LOW";
            case "3":
                return  "MEDIUM";
            case "4":
                return  "HIGH";
            case "5":
                return  "VERY_HIGH";
            default:
                break;
        }
    },
    uuidCheck:function(uuid){
        let regex = /^[a-z,0-9,-]{36,36}$/;
        if(!regex.test(uuid)){
            this.$router.go(-1)
        }
    },
    checkConsoleLog(msg){
        if(this.$route.query.debug=="Y"){
            console.log(msg);
        }
    },
    async getWidth(page,currentTable){
        let user = window.localStorage.getItem('user');
        let userData = JSON.parse(user)
        let customWidthData = {};
        await PersonalSettingService.getTableWidth(userData.userid)
        .then(response => {
            let responseData = response.data;
            if(page == "Activity"){
                if(responseData["Activity"]){
                    currentTable.forEach(el=>{
                        el.width = responseData["Activity"][0].width[el.value];
                    })
                }
                customWidthData = currentTable;
            }else{
                if(responseData && responseData[page]){
                    let data = responseData[page].filter(el =>el.customer_view_id == currentTable.view_id);
                    if(data.length > 0){
                        customWidthData = data[0].width;
                    }
                }
            }
            
        })
        .catch(response => {
            console.log(response)
        }) 
        return customWidthData
    },
  },
});

Vue.use(VueTelInputVuetify, {
  vuetify,
});
Vue.use(ColumnsResizableDirective);
Vue.use(VueColumnsResizableVuetify);
new Vue({  
  router,
  vuetify,
  store,
  Chart,
  i18n,
  render: h => h(App)
}).$mount('#app')
